import React from "react";

function Navigation(){
	return (
			<header id="header">
				<h1><a href="index.html">Kally Wu</a></h1>
				<nav class="links">
					<ul>
					</ul>
				</nav>
			</header>
	)
}

export default Navigation;